import { graphql, Link, navigate, PageProps } from 'gatsby';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormSearchSubmitButton from '../components/FormSearchSubmitButton';
import NoPostsWarning from '../components/NoPostsWarning';
import Pagination from '../components/Pagination';
import PostsGrid, { PostsGridSkeleton } from '../components/PostsGrid';
import SEO from '../components/Seo';
import Subscribe from '../components/Subscribe';
import { CursorProps, PostEntryProps } from '../types';
import { searchRemotePosts } from '../utils/searchRemotePosts';

interface FormData {
  search?: string;
}

interface DataProps {
  posts?: {
    nodes?: PostEntryProps[];
  };
  newsPressPages?: {
    nodes?: {
      id: string;
      path?: string;
      pageContext?: ContextProps;
    }[];
  };
}

interface ContextProps {
  categoryName?: string;
  categorySlug?: string;
  currentPage: number;
  pageCount: number;
  pageSize: number;
  skip: number;
}

interface LocationProps {
  isSearchQuery?: boolean;
}

const PAGE_SIZE = 13;

export function Head({
  location,
  data,
}: {
  location: { pathname: string };
  data: DataProps;
}) {
  const newsPressPages = data.newsPressPages?.nodes;
  const hasPressPage = newsPressPages?.some(
    (page) => page.pageContext?.categorySlug === 'press'
  );
  return (
    <SEO
      title={hasPressPage ? 'News / Press' : 'News'}
      description="News, press, and media kit for the Council for Christian Colleges & Universities (CCCU). The latest from leaders in Christian higher education."
      pathname={location.pathname}
    />
  );
}

export default function ArchivePage({
  data,
  pageContext,
  location,
}: PageProps<DataProps, ContextProps, LocationProps>) {
  // console.log(isSearchQuery);
  // const isSearchQuery = true;
  const newsPressPages = data.newsPressPages?.nodes;
  const hasPressPage = newsPressPages?.some(
    (page) => page.pageContext?.categorySlug === 'press'
  );

  const allPosts = data.posts?.nodes;
  const { categoryName, categorySlug, currentPage, pageCount } = pageContext;

  const hasNextPage = pageCount > 1 && currentPage < pageCount;
  const hasPrevPage = pageCount > 1 && currentPage > 1;

  const basePath = categorySlug ? `/news-press/${categorySlug}` : `/news-press`;

  const prevPageLink =
    currentPage - 1 > 1 ? `${basePath}/page/${currentPage - 1}` : `${basePath}`;
  const nextPageLink = `${basePath}/page/${currentPage + 1}`;

  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [posts, setPosts] = useState<PostEntryProps[] | undefined>(allPosts);
  const [isLoading, setIsLoading] = useState<boolean>(
    location.state?.isSearchQuery ||
      new URLSearchParams(location.search).has('search') ||
      false
  );
  const [pageInfo, setPageInfo] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>();

  const defaultCursor = {
    start: '',
    end: '',
  };
  const [cursor, setRawCursor] = useState<CursorProps>(defaultCursor);

  const setCursor = (newCursor: CursorProps) => {
    setRawCursor({ ...defaultCursor, ...newCursor });
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const watchSearch = watch('search');

  async function searchPosts(query?: string) {
    if (!query) {
      return allPosts;
    }

    const remoteData = await searchRemotePosts({
      query,
      cursor,
      perPage: PAGE_SIZE,
    });

    const searchedPosts = remoteData
      ? remoteData?.edges
          ?.filter((edge) => Boolean(edge.node))
          .map((edge) => edge.node as PostEntryProps) || allPosts
      : allPosts;

    setPosts(searchedPosts);
    setPageInfo(remoteData?.pageInfo);

    setIsLoading(false);
  }

  useEffect(() => {
    if (searchQuery) {
      navigate(`${location.pathname}?search=${searchQuery}`, {
        replace: false,
      });

      searchPosts(searchQuery);
    } else {
      if (new URLSearchParams(location.search).has('search')) {
        navigate(`${location.pathname}`, { replace: false });
      }

      setIsSearch(false);
      setIsLoading(false);
      setError(null);
      setPosts(allPosts);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (watchSearch) {
      setIsSearch(true);
      setIsLoading(true);
    }

    if (watchSearch === searchQuery) {
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      if (watchSearch) {
        setSearchQuery(watchSearch);
      } else {
        setSearchQuery('');
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [watchSearch, cursor]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchParam = params.get('search');
    if (searchParam) {
      setValue('search', searchParam);
    }
  }, []);

  const onSubmit = handleSubmit((formData, e) => {
    e?.preventDefault();
    navigate(`${basePath}?search=${formData.search}`);
  });

  return (
    <main className="page-news">
      <section className="container section-m-t section-m-b">
        <div className="row">
          <div className="col-xs-12 col-lg-10 col-lg-offset-1">
            <div className="title-search title-search--full-width">
              <div className="title-search__inner">
                <div className="title-search__title">
                  <h1 className="h2">
                    {hasPressPage ? 'News / Press' : 'News'}
                  </h1>
                </div>
                <div className="title-search__form">
                  <form
                    onSubmit={onSubmit}
                    className="searchform"
                    action={basePath}
                  >
                    <input
                      type="search"
                      placeholder={`Search ${categoryName}`}
                      // disabled={isLoading}
                      {...register('search')}
                    />
                    <FormSearchSubmitButton
                    // loading={isLoading}
                    />
                  </form>
                </div>
              </div>
            </div>
            {newsPressPages && newsPressPages.length > 1 && (
              <div className="nav-pagination">
                <div className="nav-pagination__inner">
                  <nav className="nav">
                    <ul>
                      <li>
                        <Link
                          to="/news-press"
                          className={!categorySlug ? 'active' : ''}
                        >
                          All
                        </Link>
                      </li>
                      {newsPressPages.map((page) => {
                        if (!page.path || !page.pageContext) {
                          return null;
                        }
                        return (
                          <li key={page.id}>
                            <Link
                              to={page.path}
                              className={
                                categorySlug === page.pageContext.categorySlug
                                  ? 'active'
                                  : ''
                              }
                            >
                              {page.pageContext.categoryName}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                  {(isSearch && pageInfo && (
                    <Pagination
                      prevPage={
                        pageInfo.hasPreviousPage
                          ? {
                              onClick: () =>
                                setCursor({ start: pageInfo.startCursor }),
                            }
                          : null
                      }
                      nextPage={
                        pageInfo.hasNextPage
                          ? {
                              onClick: () =>
                                setCursor({ end: pageInfo.endCursor }),
                            }
                          : null
                      }
                    />
                  )) || (
                    <Pagination
                      prevPage={hasPrevPage ? { url: prevPageLink } : null}
                      nextPage={hasNextPage ? { url: nextPageLink } : null}
                    />
                  )}
                </div>
              </div>
            )}
            {(isLoading && (
              <div className="results__wrapper">
                <div className="results__featured">
                  <div className="results__featured__primary">
                    <PostsGridSkeleton count={1} image excerpt meta />
                  </div>
                  <div className="results__featured__secondary">
                    <PostsGridSkeleton count={4} image meta />
                  </div>
                </div>
                <div className="results__standard">
                  <PostsGridSkeleton count={8} meta />
                </div>
              </div>
            )) ||
              (!!posts?.length && (
                <div className="results__wrapper">
                  <div className="results__featured">
                    <div className="results__featured__primary">
                      <PostsGrid posts={posts.slice(0, 1)} image excerpt meta />
                    </div>
                    <div className="results__featured__secondary">
                      <PostsGrid posts={posts.slice(1, 5)} image meta />
                    </div>
                  </div>
                  <div className="results__standard">
                    <PostsGrid posts={posts.slice(5, 14)} meta />
                  </div>
                </div>
              )) || <NoPostsWarning />}
            <div className="nav-pagination">
              <div className="nav-pagination__inner">
                {(isSearch && pageInfo && (
                  <Pagination
                    prevPage={
                      pageInfo.hasPreviousPage
                        ? {
                            onClick: () =>
                              setCursor({ start: pageInfo.startCursor }),
                          }
                        : null
                    }
                    nextPage={
                      pageInfo.hasNextPage
                        ? {
                            onClick: () =>
                              setCursor({ end: pageInfo.endCursor }),
                          }
                        : null
                    }
                  />
                )) || (
                  <Pagination
                    prevPage={hasPrevPage ? { url: prevPageLink } : null}
                    nextPage={hasNextPage ? { url: nextPageLink } : null}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Subscribe className="section-m-b" type="archive" />
    </main>
  );
}

export const pageQuery = graphql`
  query ($categorySlug: String, $skip: Int = 0, $pageSize: Int = 13) {
    posts: allWpPost(
      sort: { order: DESC, fields: date }
      limit: $pageSize
      skip: $skip
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $categorySlug } } } }
      }
    ) {
      nodes {
        ...PostEntry
      }
    }
    newsPressPages: allSitePage(filter: { path: { glob: "/news-press/*" } }) {
      nodes {
        id
        path
        pageContext
      }
    }
  }
`;
