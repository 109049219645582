interface FetchOptionsProps {
  variables?: {
    [key: string]:
      | string
      | number
      | boolean
      | string[]
      | number[]
      | boolean[]
      | null
      | undefined;
  };
  headers?: { [key: string]: string };
}

const API_URL = `${process.env.GATSBY_WORDPRESS_URL}/graphql`;

export async function fetchAPI<T>(
  query: string,
  { variables, headers: headersParams }: FetchOptionsProps = {}
): Promise<T> {
  if (!API_URL) {
    throw new Error('API URL is not defined');
  }

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  if (process.env.WP_APP_AUTH_BASE64) {
    headers.append('Authorization', `Basic ${process.env.WP_APP_AUTH_BASE64}`);
  }

  if (headersParams && Object.keys(headersParams).length) {
    Object.keys(headersParams).forEach((key) => {
      headers.append(key, headersParams[key]);
    });
  }

  const res = await fetch(API_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  const json: { data: T; errors?: string } = await res.json();

  if (json.errors) {
    console.log(query);
    console.error(json.errors);
    throw new Error('Failed to fetch API');
  }
  return json.data;
}
