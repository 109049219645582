import { Link } from 'gatsby';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';

interface ArrowProps {
  url?: string;
  onClick?: () => void;
}
interface Props {
  prevPage?: ArrowProps | null;
  nextPage?: ArrowProps | null;
}

function PageArrow({
  arrow,
  direction,
}: {
  arrow?: ArrowProps | null;
  direction: 'next' | 'prev';
}) {
  const icon = direction === 'next' ? <MdArrowForward /> : <MdArrowBack />;

  if (typeof arrow?.onClick !== 'undefined') {
    return (
      <button type="button" onClick={arrow.onClick}>
        <MdArrowBack />
      </button>
    );
  }

  if (arrow?.url) {
    return <Link to={arrow.url}>{icon}</Link>;
  }

  return (
    <button type="button" disabled>
      {icon}
    </button>
  );
}

export default function Pagination({ nextPage, prevPage }: Props) {
  if (!nextPage && !prevPage) {
    return null;
  }

  return (
    <div className="pagination">
      <ul>
        <li>
          <PageArrow arrow={prevPage} direction="prev" />
        </li>
        <li>
          <PageArrow arrow={nextPage} direction="next" />
        </li>
      </ul>
    </div>
  );
}
