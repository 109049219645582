import { MdWarning } from 'react-icons/md';

export default function NoPostsWarning() {
  return (
    <div className="no-posts">
      <div className="no-posts__inner">
        <MdWarning />
        <div className="narrow">
          <h5 className="no-posts__title">
            Nothing found based on your search.
          </h5>
          <p>Please try being less specific or using different keywords.</p>
        </div>
      </div>
    </div>
  );
}
