import { PostEntryProps } from '../types';
import { fetchAPI } from './api';

export async function searchRemotePosts({
  query,
  cursor,
  perPage = 10,
}: {
  query?: string;
  cursor?: any;
  perPage?: number;
}) {
  const res = await fetchAPI<{
    posts?: {
      edges?: { node?: PostEntryProps }[];
      pageInfo?: {
        hasNextPage?: boolean;
        hasPreviousPage?: boolean;
        endCursor?: string;
        startCursor?: string;
      };
    };
  }>(
    `
  query SearchQuery(
    $search: String, 
    $after: String
    $before: String, 
    $first: Int, 
    $last: Int
    ) {
    posts(
      where: {search: $search}, 
      after: $after
      before: $before
      first: $first
      last: $last
      ) {
      edges {
        node {
          title
          date
          categories {
            nodes {
              slug
              name
            }
          }
          uri
          excerpt
          featuredImage {
            node {
              sourceUrl
              sizes
              srcSet
              mediaDetails {
                height
                width
              }
            }
          }
          categories {
            nodes {
              uri
              name
              slug
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
    }
  }
`,
    {
      variables: {
        search: query,
        after: cursor?.end,
        before: cursor?.start,
        first: !cursor?.start ? perPage : undefined,
        last: cursor?.start ? perPage : undefined,
      },
    }
  );

  return res?.posts;
}
