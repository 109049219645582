import { MdSearch } from 'react-icons/md';
import LoadingSpinner from './LoadingSpinner';

export default function FormSearchSubmitButton({
  loading,
}: {
  loading?: boolean;
}) {
  return (
    <button
      type="submit"
      aria-label="Search"
      disabled={loading}
      style={{ position: 'relative' }}
    >
      <span
        style={{
          opacity: loading ? 0 : 1,
          transition: 'opacity var(--transition)',
        }}
      >
        <MdSearch />
      </span>
      <span
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {loading && <LoadingSpinner />}
      </span>
    </button>
  );
}
